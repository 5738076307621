<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { transformImg } from '@shein/common-function'

export default {
  name: 'CatSelectionTagEnter',
  functional: true,
  components: {
    Icon,
  },
  props: {
    tagData: {
      type: Object,
      default: () => ({})
    },
    pageCommonInfo: {
      type: Object,
      default: () => ({})
    },
    showSelectionDrawer: {
      type: Boolean,
      default: false
    },
    qualityFloor: {
      type: String,
      default: 'SHOW'
    },
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { props, listeners }) {
    const { tagData, pageCommonInfo, showSelectionDrawer,  qualityFloor } = props
    const { langPath_rp = '', GB_cssRight_rp = false } = pageCommonInfo
    const { descKeyList = [], items: tagList = [] } = tagData

    return (
      <div class={['product-selection', qualityFloor === 'NEW' ? 'product-selection_new' : '']}>
        <div
          class="product-selection__tag"
          onClick={e => {
            listeners.handleSelectionDrawer(true)
            e.stopPropagation()
          }}
        >
          <div 
            class="product-selection__tag-title"
            {...{ directives: [{ name: 'expose', value: { id: '1-6-4-71' } }] }}
            style={ qualityFloor === 'SHOW' && {
              backgroundImage: `url(${langPath_rp}/pwa_dist/images/proSelect_bg${GB_cssRight_rp ? '_ar-3091a94cd3' : '-aa6a620d31'}.png)`
            }}
          >
            {qualityFloor === 'NEW' && 
            <Icon name="sui_icon_qualidade_14px_color" size="14px" />
            }
            <span class="product-selection__tag-text">
              ProSelect
            </span>
            
          </div>
          {descKeyList.length ?
            (
              descKeyList.map((item, index) => {
                if(!item.langKeyEn) return
                return (
                  <div 
                    class={['product-selection__tag-item', index !== 0 ? 'mar-left-ten' : '']}
                  >
                    <Icon
                      name="sui_icon_success_14px_1"
                      size="14px"
                      color={ qualityFloor === 'SHOW' ? '#000' : '#745441'}
                    />
                    {item.langKeyEn}
                  </div>
                )
              })
            ) : null
          }
        </div>
        <div class="product-selection__tag-more">
          <Icon name="sui_icon_more_right_16px_2" size="16px" color={qualityFloor === 'SHOW' ? '#000' : '#745441'}  />
        </div>
        <s-drawer
          visible={showSelectionDrawer}
          custom-class="product-selection__drawer"
          show-close={false}
          vOn:close-from-mask={() => listeners.handleSelectionDrawer(false)}
          direction="btt"
        >
          <div
            slot="top"
            class="product-selection__drawer-header"
          >
            <Icon name="sui_icon_close_16px" size="16px"
              onClick={() => {
                listeners.handleSelectionDrawer(false)
              }}
            />
            <div class='product-selection__drawer-header-title'>
              ProSelect
            </div>
            <div class='product-selection__drawer-header-subtitle'>
              Expertly curated for quality shopping
            </div>
          </div>
          <div class="product-selection__drawer-wrap">

            {tagList.length ? (
              tagList.map((item) => {
                return (
                  <div class="product-selection__drawer-tag-item" style={{ background: item.titleBgColor }}>
                    <div 
                      class="item-title"
                      style={{ color: item.titleTextColor || '' }}
                    >
                      {item.titleKeyEn}
                    </div>
                    <div class="item-sub-title" style={{ color: item.titleDescTextColor || '' }}>{item.titleDescKeyEn}</div>
                    <div class="item-content">
                      {
                        item.tipsType === 'text' ? (
                          <div class="tag-title">{item.tipsKeyEn}</div>
                        ) : null
                      }
                      {
                        item.tipsType === 'picture' && item.tipsPicInfo && item.tipsPicInfo.src ? (
                          <div class="tag-title tt-center">
                            <img
                              class="tag-picture lazyload"
                              src={item.tipsPicInfo.src}
                              data-src={transformImg({ img: item.tipsPicInfo.src })}
                            />
                          </div>
                        ) : null
                      }
                      {
                        item?.content?.map((contentItem, index) => {
                          let listHtml = ''
                          switch (contentItem.type) {
                            case 'checkList':
                              listHtml = (
                                <div class="tag-checklist">
                                  <Icon name="sui_icon_success_14px_1" size="14px" color="#000" />
                                  {contentItem.langKeyEn}
                                </div>
                              )
                              break
                            case 'pointList':
                              listHtml = (
                                <div class={['tag-pointlist', index === 0 ? 'tag-first-tp' : '']}>
                                  <Icon name="sui_icon_select_disable_20px" size="6px" color="#000" />
                                  {contentItem.langKeyEn}
                                </div>
                              )
                              break
                            case 'text':
                              listHtml = (
                                <div class="tag-plist">
                                  {contentItem.langKeyEn}
                                </div>
                              )
                              break
                          }
                          return listHtml
                        })
                      }
                    </div>
                  </div> 
                )
              })
            ) : null }
          </div>
        </s-drawer>
      </div>
    )
  }
}
</script>

<style lang="less">
.product-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2133rem;
  height: 1.12rem;
  padding: 0 0.32rem;
  overflow: hidden;
  background: #fff;
  &__tag {
    flex: 1;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(90deg, rgba(244, 213, 114, 0.34) 24.53%, rgba(244, 213, 114, 0.00) 100%);
  }
  &__tag-title {
    color: #FCDF9D;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0.0533rem 0.2799rem 0.0533rem 0.1067rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // width: 1.97rem;
    flex-shrink: 0;
    box-sizing: border-box;
    .font-dpr(24px);
    .margin-r(0.1067rem);
  }
  &__tag-item {
    color: #000;
    .font-dpr(22px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .sui-icon-common__wrap {
      margin-right: 0.0533rem;
      /* stylelint-disable-next-line declaration-no-important */
      line-height: 1!important;
    }
  }
  .mar-left-ten {
    .margin-l(0.16rem);
  }
  &__tag-more {
    .margin-l(0.2133rem);
    [class*='iconfont'] {
     color: #959595;
    }
  }
}
.product-selection_new{
  margin-top: 0.21rem;
  margin-bottom: 0;
  padding: 0 0.08rem;
  height: auto;
  border-radius: 0.05333rem;
  background: linear-gradient(90deg, #FFEED5 0.21%, #FFF6E8 24.17%, #FFFAF2 99.81%);
  height: 0.61333rem;
  .product-selection{
    &__tag{
      background: none;
    }
    &__tag-title {
      display: flex;
      align-items: center;
      padding: 0 0.16rem 0 0.10667rem;
      height: 0.45333rem;
      border-radius: 0.05333rem;
      background: linear-gradient(90deg, #000 0.02%, #505050 99.98%);
      box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.12) inset;
      .margin-r(0.21rem);
    }
    &__tag-text{
      margin-left: 0.05333rem;
      color: #FFFAEE;
      font-size: 0.29333rem;
      font-weight: 600;
    }
    &__tag-item{
      color: #745441;
    }
  }
}
.product-selection {
  &__drawer {
    color: #000;
    font-family: SF UI Text;
    font-style: normal;
    line-height: normal;
    .S-drawer__header_normal {
      min-height: auto;
    }
  }
  &__drawer-header {
    position: relative;
    background: linear-gradient(180deg, #FEECC5 0%, #FFF 100%);
    .sui-icon-common__wrap {
      position: absolute;
      top: 0.0533rem;
      right: 0.0533rem;
    }
  }
  &__drawer-header-title {
    color: #000;
    text-align: center;
    .font-dpr(40px);
    font-weight: 700;
    line-height: 1;
    padding-top: 0.64rem;
    padding-bottom: 0.16rem;
    text-transform: capitalize;
  }
  &__drawer-header-subtitle {
    color: #000;
    text-align: center;
    .font-dpr(24px);
    line-height: 1;
    font-weight: 400;
    padding-bottom: 0.32rem;
    text-transform: capitalize;
  }
  &__drawer-wrap {
    padding: 0.32rem 0.32rem 0.8533rem 0.32rem;
  }
  &__drawer-tag-item {
    padding: .32rem 0.1067rem 0.1067rem 0.1067rem;
    border-radius: 0.16rem;
    background: linear-gradient(107deg, #424242 0%, #000 99.05%);
    margin-bottom: 0.4267rem;
    .item-title {
      color: #FCDF9D;
      text-align: center;
      .font-dpr(32px);
      font-weight: 700;
      line-height: 1;
      padding: 0.32rem 0 0.1067rem;
    }
    .item-sub-title {
      color: rgba(252, 223, 157, 0.60);
      text-align: center;
      .font-dpr(24px);
      font-weight: 400;
      margin-bottom: 0.32rem;
    }
    .item-content {
      padding: 0.32rem;
      border-radius: 0.08rem;
      background: #FFF;
    }
    .tag-title {
      .font-dpr(28px);
      line-height: 1;
      padding-bottom: 0.32rem;
      border-bottom: 1px dashed @sui_color_gray_weak1;
    }
    .tag-picture {
      height: auto;
      width: 100%;
    }
    .tt-center {
      text-align: center;
    }
    .tag-checklist {
      position: relative;
      .font-dpr(24px);
      font-weight: 400;
      line-height: 1;
      padding-top: .32rem;
      padding-left: 0.48rem;
      .sui-icon-common__wrap {
        position: absolute;
        top: 0.32rem;
        left: 0;
        line-height: 1 !important; /* stylelint-disable-line declaration-no-important */
      }
    }
    .tag-pointlist {
      position: relative;
      padding-left: 0.48rem;
      .font-dpr(28px);
      font-weight: 400;
      line-height: normal;
      padding-top: .16rem;
      .sui-icon-common__wrap {
        position: absolute;
        top: .18rem;
        left: 0;
        line-height: 1 !important; /* stylelint-disable-line declaration-no-important */
      }
    }
    .tag-first-tp {
      padding-top: .32rem;
      .sui-icon-common__wrap {
        top: .34rem;
      }
    }
    .tag-plist {
      .font-dpr(24px);
      font-weight: 400;
      line-height: normal;
      padding-top: .32rem;
    }
  }
}
</style>
